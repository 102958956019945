@import "../../theme/variables";

.app {
  &_content {
    padding: 105px 5px 20px;

    @media @xs {
      padding: 105px 10px 20px;
    }
    @media @xl {
      padding: 30px 20px;
    }
  }
}

:global(.ant-layout) {
  &.app {
    min-height: 100vh;
  }
}

@import '../../../../theme/variables';

.container {
  height: 100%;
  min-height: 480px;
  border-radius: 20px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 30px;
  }

  .submit_button {
    background: #072c4a;
    color: #fff;

    &:disabled {
      background: #eee;
      color: #adadad;
    }
  }

  :global(.ant-input-affix-wrapper:focus),
  :global(.ant-input-affix-wrapper-focused) {
    &.input {
      box-shadow: none;
    }
  }
  .invisible {
    display: none;
  }

  .headerContainer {
    width: 100%;
    padding-top: 5px;
    // padding-bottom: 20px;
    display: flex;
    justify-content: center;
    // border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    // margin-bottom: 30px;
  }

  .header {
    font-size: 18px;
    font-weight: 700;
  }

  :global(.ant-select) {
    &.select {
      margin-top: 5px;
      width: fit-content;
      font-weight: 500;

      &:not(:global(.ant-select-customize-input)) {
        :global(.ant-select-selector) {
          border-radius: 15px;
        }
      }
    }
  }
}

.select_item {
  display: flex;
  align-items: center;
  color: @black;

  img {
    width: 18px;
    margin-right: 15px;
  }
}

.light {
  background-color: @white;
  color: @black;

  .tab {
    background-color: #eee;
    color: #adadad;
  }

  .tab_active {
    background-color: transparent;
    color: @black;
  }

  .input {
    display: flex;
    align-items: flex-end;
    input {
      color: @black;
    }
  }

  .usd {
    color: #2a618e;
  }

  .label {
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }

  :global(.ant-select) {
    &.select {
      color: @black;
    }
  }
}

:global(.ant-modal) {
  &.wrapper {
    max-width: 450px;

    :global(.ant-modal-content) {
      // background: transparent;
      border-radius: 20px;
    }

    :global(.ant-modal-body) {
      padding: 0;
    }
  }
}

.hstack {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.vstack {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  textarea {
    padding: 8px 16px;
    border-radius: 20px;
    border: 1px solid #b5cfe6;
  }
}

.label {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.info_box {
  color: var(--gray-900, #101828);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */

  white-space: pre-wrap;
}

.warning_box {
  display: flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 20px;
  background: #fff5e2;
}

.deposit_link {
  color: var(--primary-dark-navy-4, #006ca8);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
}

@import '~antd/dist/antd';
@import './variables';

#root {
  background: @primary-bg;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  color: @white;
  font-weight: 600;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

.ant-tooltip-inner {
  border-radius: 10px;
  text-align: center;

  a {
    color: @product-bg;
    text-decoration: underline;
  }
}

.ant-message {
  color: @black;
}

.ant-btn {
  text-transform: uppercase;
}

.ant-btn-background-ghost.ant-btn-primary {
  color: @white;

  &:hover,
  &:focus {
    color: @white;
  }
}

.ant-btn-background-ghost.ant-btn-primary[disabled] {
  border-color: @paragraph;
  color: @paragraph;

  &:hover {
    border-color: @paragraph;
    color: @paragraph;
  }
}

.ant-select-arrow {
  color: @paragraph;
}

.ant-table-tbody {
  & > tr.ant-table-placeholder {
    &:hover {
      & > td {
        background: transparent;
      }
    }

    .ant-empty-normal {
      color: @white;
    }
  }
}

.skiptranslate iframe {
  display: none !important;
}

/* 번역 배너로 인한 body top 조정 */
body {
  top: 0px !important;
}

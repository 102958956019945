@import "../../theme/variables";

.footer {
  display: flex;
  flex: 0 0 auto;
  padding: 24px 20px;

  @media @md {
    padding: 24px 30px;
  }
}

.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: @paragraph;

  ul {
    display: flex;
    margin-bottom: 10px;

    @media @md {
      margin-bottom: 0;
    }

    li {
      &:not(:last-child) {
        margin-right: 10px;

        @media @md {
          margin-right: 20px;
        }
      }

      a {
        color: @paragraph;
      }
    }
  }
}

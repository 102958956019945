:global(.ant-modal) {
  &.container {
    max-width: 850px;
    color: #062138;

    :global(.ant-modal-title) {
      font-size: 15px;
      font-weight: 600;
      text-align: center;
    }

    :global(.ant-modal-content) {
      width: 100%;
      max-width: 850px;
      border-radius: 20px;
      box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
        0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
    }

    :global(.ant-modal-body) {
      min-height: 415px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 20px 20px 20px;
      gap: 29px;
    }

    :global(.ant-input-affix-wrapper) {
      margin-top: 40px 20px;
      border-radius: 20px;

      input {
        color: #979797;
      }
    }
  }
}

.way {
  display: flex;
  background-color: 'red';
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  gap: 30px;
  .inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 16px;

    .inputLabel {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}

:global(.ant-select) {
  :global(.ant-select-selector) {
    height: 52px !important;
    padding: 0 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
  }

  :global(.ant-select-selection-item) {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &.select {
    width: 100%;
    color: #062138;
    height: 52px;

    &.ant-select-selection-item {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &:not(:global(.ant-select-customize-input)) {
      :global(.ant-select-selector) {
        border-radius: 200px;
      }
    }

    &_item {
      display: flex;
      align-items: center;
      color: #062138;

      img {
        width: 18px;
        margin-right: 15px;
      }
    }
  }
}

.select_item {
  display: flex;
  align-items: center;
  color: #062138;

  img {
    width: 18px;
    margin-right: 15px;
  }
}

.descr {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.contact {
  font-size: 12px;
  text-align: center;
  width: 100%;
  padding: 15px 20px 15px 20px;
  border-radius: 20px 0px 0px 0px;
  background-color: #e3f1fc;
  font-weight: 500;
  strong {
    font-weight: 700;
  }
}

@import "../../theme/variables";

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //flex: 1;
}

.btns {
  display: flex;
  flex-direction: column;

  @media @xl {
    flex-direction: row;
    margin-right: 50px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;


    @media @xl {
      width: 150px;
      margin-top: 0;
      margin-right: 15px;
    }

    img {
      width: 16px;
      margin-right: 10px;
    }
  }
}

:global(.ant-avatar) {
  &.avatar {
    background: @sider-bg;
  }
}

.user {
  display: flex;
  align-items: center;

  padding: 0;
  border: 0;
  background-color: transparent;
  outline: 0;

  @media @xl {
    margin-left: 45px;
  }

  &_name {
    margin: 0 15px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.dropdown_menu {
  padding: 5px 0;
  background-color: @white;

  a {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: @black;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;

    >span {
      margin-left: 15px;
    }

    &:hover {
      color: @black;
    }
  }

  .active {
    color: @primary-color;

    &:hover {
      color: @primary-color;
    }
  }
}

.mobile_menu {
  height: 100%;
  padding: 25px 30px;
  background: @sider-bg;

  ul {
    margin-top: 40px;

    li {

      margin-bottom: 10px;
      padding: 10px 0;

      a {
        display: flex;
        align-items: center;
        color: @paragraph;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;

        &:hover {
          color: @paragraph;
        }
      }

      .active {
        color: @white;

        &:hover {
          color: @white;
        }
      }

      span {
        margin-left: 15px;
      }

    }
  }

  .locale {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}


@import "../../theme/variables";

.wrap {
  display: flex;
  margin-right: 15px;
  cursor: pointer;

  @media @xl {
    margin-right: 0;
    margin-left: 40px;
  }

  :global(.ant-badge-count) {
    background: @white;
    color: @list-divider-color;
  }
}

@import "../../theme/variables";

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 0 20px;
  background: #072A47;
  z-index: 2;

  @media @xl {
    position: relative;
    padding: 0 30px;
  }

  h2 {
    width: 34px;

    @media @xl {
      width: auto;
    }
  }
}

.logo {
  @media @xl {
    display: none;
  }
}

.title {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  bottom: -10px;
  left: 0;
  padding: 0 20px;
  background: #072A47;
  color: @primary-color;
  font-size: 14px;

  @media @xl {
    position: static;
    padding: 0;
    color: inherit;
    font-size: 16px;
  }

  .divider {
    flex-grow: 1;
    height: 1px;
    margin-left: 20px;
    background-color: #0E3F5A;

    @media @xl {
      display: none;
    }
  }
}

@import '../../../../theme/variables';

:global(.ant-modal) {
  :global(.ant-modal-content) {
    border-radius: 20px;
    max-width: 450px;
    margin: 0 auto;
  }
  :global(.ant-modal-body) {
    padding: 0;
  }
}

.container {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  color: black;
  border-radius: 20px;

  padding: 40px 20px;
  margin: 0 auto;

  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
  .check_icon {
    width: 60px;
    height: 60px;
    padding-bottom: 29px;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: black;
    margin-top: 29px;
    margin-bottom: 20px;
  }

  .summary {
    margin-bottom: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    .product_item {
      display: flex;
      align-items: center;
      color: black;
      gap: 5px;

      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;

      img {
        width: 18px;
      }
    }
    .currency_item {
      display: flex;
      align-items: center;
      gap: 10px;
      color: black;
      background-color: #ecf7fa;
      padding: 10px;
      border-radius: 20px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-align: left;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .button {
    background: #072c4a;
    color: #fff;
  }
}

@import '../../../../theme/variables';

.container {
  height: 100%;
  min-height: 480px;
  border-radius: 20px;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);

  .tabs {
    display: flex;
  }

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 48px;
    transition: color, background-color 0.3s;

    button {
      width: 100%;
      height: 100%;
      padding: 0;
      border: 0;
      outline: 0;
      background-color: transparent;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 30px;
  }

  .estimated {
    margin-top: 15px;
    font-size: 12px;

    strong {
      margin-left: 10px;
      font-size: 16px;
    }
  }

  .submit_button {
    background: #072c4a;
    color: #fff;
  }

  .minimum {
    display: flex;
    align-items: center;
    margin-top: 15px;
    font-size: 12px;

    img {
      visibility: hidden;
      margin-right: 5px;
    }

    strong {
      margin-left: 10px;
    }

    &.error {
      color: @red-text;

      img {
        visibility: visible;
      }
    }
  }

  :global(.ant-input-affix-wrapper:focus),
  :global(.ant-input-affix-wrapper-focused) {
    &.input {
      box-shadow: none;
    }
  }

  .usd {
    margin-top: 10px;
  }

  .radio {
    margin: 15px 0;
  }

  .invisible {
    display: none;
  }

  .headerContainer {
    width: 100%;
    padding-top: 5px;
    // padding-bottom: 20px;
    display: flex;
    justify-content: center;
    // border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    // margin-bottom: 30px;
  }

  .header {
    font-size: 18px;
    font-weight: 700;
  }

  .label {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #101828;
    font-style: normal;
  }

  :global(.ant-select) {
    &.select {
      margin-top: 5px;
      width: 100%;
      font-weight: 500;

      &:not(:global(.ant-select-customize-input)) {
        :global(.ant-select-selector) {
          border-radius: 15px;
        }
      }
    }
  }
}

.toggle {
  display: flex;
  align-items: center;
  background: #e3f1fc;
  border-radius: 15px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
  padding: 5px;
  font-size: 14px;
  font-weight: 700;
  width: fit-content;

  .toggle_item {
    display: flex;
    align-items: center;
    padding: 5px 23px;
    cursor: pointer;
    border-radius: 15px;
    border: none;
    background: none;
  }

  .toggle_item_active {
    display: flex;
    align-items: center;
    padding: 5px 23px;
    cursor: pointer;
    border-radius: 15px;
    border: none;

    color: white;
    background: linear-gradient(90deg, #072c4a 0%, #054067 100%);
  }
}

.select_item {
  display: flex;
  align-items: center;
  color: @black;

  img {
    width: 18px;
    margin-right: 15px;
  }
}

.light {
  background-color: @white;
  color: @black;

  .tab {
    background-color: #eee;
    color: #adadad;
  }

  .tab_active {
    background-color: transparent;
    color: @black;
  }

  .estimated,
  .minimum {
    color: #666;

    strong {
      color: @paragraph;
    }
  }

  .input {
    display: flex;
    align-items: flex-end;
    input {
      color: @black;
    }
  }

  .usd {
    color: #2a618e;
  }

  .label {
    color: #666;
  }

  :global(.ant-select) {
    &.select {
      color: @black;
    }
  }
}

.dark {
  background-color: rgba(@primary-bg-2, 0.5);
  color: @white;

  .tab {
    background-color: @primary-bg-2;
    color: #2a618e;
  }

  .tab_active {
    background-color: transparent;
    color: @white;
  }

  .estimated,
  .minimum {
    color: @paragraph;

    strong {
      color: @white;
    }
  }

  .input {
    border-color: #2a618e;
    background-color: transparent;

    input {
      background-color: transparent;
      color: @white;
    }
  }

  .usd {
    color: #38c6f4;
  }

  .label {
    color: @paragraph;
  }

  :global(.ant-select) {
    &.select {
      :global(.ant-select-selector) {
        border-color: @primary-color;
        background-color: #062138;
      }
    }

    .select_item {
      color: @paragraph;
    }
  }

  :global(.ant-btn-background-ghost.ant-btn-primary[disabled]) {
    color: #2a618e;
  }
}

.current_product {
  .label {
    margin: 40px 0;
  }
}

.not_verified {
  margin-top: 135px;
  padding: 0 30px;
}

.LanguageToggle {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  min-width: 128px;
  padding: 8px 12px;

  margin: 40px;
  background-color: white;

  border-radius: 8px;
  cursor: pointer;
  gap: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  opacity: 0; /* 기본적으로 숨김 상태 */
  visibility: hidden;

  p {
    width: 100%;
    text-align: center;
    color: white;
    font-size: 8px;
  }
}

.LanguageToggle.visible {
  opacity: 0.8;
  visibility: visible;
}

.LanguageToggle.hidden {
  opacity: 0;
  visibility: hidden;
}

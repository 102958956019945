@import "../../theme/variables";

.content {
  height: 100%;
  background: @sider-bg;

  @media @xl {
    position: fixed;
    width: 243px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 20px;
  }

  nav {
    margin-top: 30px;

    @media @xl {
      margin-top: 70px;
    }
  }
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 28px;
  margin-bottom: 17px;
  padding: 0 20px;
  color: @paragraph;
  font-size: 12px;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 15px;
  }

  &:hover {
    color: @paragraph;
  }
}

.item_active {
  color: @white;

  svg {
    path {
      fill: @primary-color;
    }
  }

  &:hover {
    color: @white;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    border-radius: 0 100px 100px 0;
    background-color: @primary-color;
    content: '';
  }

  &_stroke {
    svg {
      path {
        fill: none;
        stroke: @primary-color;
      }
    }
  }
}

.category_title {
  margin: 30px 0 17px;
  padding: 0 20px;
  color: @primary-color;
  font-size: 12px;
}

.sub_item {
  display: flex;
  align-items: center;
  min-height: 28px;
  margin-left: 53px;
  padding-left: 15px;
  border-left: 1px solid @paragraph;
  color: @paragraph;

  &:hover {
    color: @paragraph;
  }

  &_active {
    color: @white;

    &:hover {
      color: @white;
    }
  }
}

.mobile_trigger {
  position: fixed;
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 10px;
  z-index: 3;
}

.trigger {
  display: flex;
  justify-content: space-around;
  width: 40px;
  padding: 10px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  outline: 0;

  span {
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: @primary-color;
  }
}

:global(.ant-layout-sider-collapsed) {
  .content {
    width: 96px;

    header {
      justify-content: center;
    }
    .logo {
      display: none;
    }
  }

  .item {
    justify-content: center;
    margin-bottom: 40px;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 0;
    }

    span {
      display: none;
    }
  }

  .category_title,
  .sub_item {
    display: none;
  }
}

.referral {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  img {
    margin-left: -20px;
  }

  & > span {
    margin: 15px 0;
    text-align: center;

    strong {
      font-weight: 600;
    }
  }
}

@import "../../../../theme/variables";

.container {
  width: 250px;
  background-color: @white;

  &_header {
    padding: 10px 20px;
    text-align: right;

    button {
      padding: 0;
      border: 0;
      background-color: transparent;
      color: #2A618E;
      font-size: 10px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.list {
  max-height: 320px;
  overflow-y: auto;
}

.item {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px 20px 10px 10px;
  background-color: @white;
  color: @black;
  cursor: pointer;

  img {
    width: 24px;
  }

  div {
    display: flex;
    flex-direction: column;
    padding: 0 7px;
  }

  &_title {
    margin-bottom: 0;
    font-size: 11px;
    font-weight: bold;
  }

  &_msg {
    font-size: 10px;
    line-height: 12px;
    font-weight: 300;
  }

  &_date {
    margin-top: 5px;
    color: #2A618E;
    font-size: 9px;
  }

  &:hover {
    background-color: #ECFAFF;
  }

  &_new {
    &::after {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: @blue-text;
      content: '';
    }
  }
}

.ant-input-group-wrapper {
  margin-top: 5px;
}

.ant-input {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.ant-input-group-addon {
  color: black !important;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}


.container {
  margin-top: 30px;

  & > div {
    &:not(&:last-child) {
      margin-right: 10px;
    }
  }
}

.input {
  width: 40px !important;
  height: 40px;
  border-color: #DEDEDE;
  border-width: 0 0 1px 0;
  font-size: 28px;
  font-weight: 300;
}

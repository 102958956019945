:global(.ant-modal) {
  &.container {
    max-width: 410px;

    :global(.ant-modal-content) {
      background: transparent;
      border-radius: 20px;
    }

    :global(.ant-modal-body) {
      padding: 0;
    }
  }
}


.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;
}

@import "../../../../theme/variables";


:global(.ant-modal) {
  &.container {
    max-width: 464px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: @black;

  img {
    width: 50px;
  }

  h3 {
    margin-top: 25px;
    color: @paragraph;
  }

  p {
    margin-top: 10px;
  }

  & > div {
    margin-top: 15px;
  }

  button {
    min-width: 150px;
    margin-top: 25px;
  }
}
